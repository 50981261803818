.popover {
  min-width: 18rem;
}
.popover.right {
  text-align: left !important;
}

hi-tile {
  display: block;
}

strong.font-bold {
  font-size: inherit;
}

.content-max-width {
  max-width: 1536px;
  margin: auto;
}
@media (max-width: 1536px) {
  .content-max-width.gutters {
    padding: 0 1.25rem;
  }
}
@media print {
  .content-max-width {
    display: block !important;
  }
}

.ptxs {
  padding-top: 2.5px;
}

.svg-icon.icon-l {
  font-size: 1.5rem;
}

.poll-choice.type-stacked {
  height: auto !important;
}

.eab-logo-color {
  background-repeat: no-repeat;
  background-position: center center;
}
.eab-logo-color.navigate-new,
.eab-logo-color.navigate-old {
  background-image: url(%__PATH_RESOURCES__%/navigate.png) !important;
  background-size: auto 100%;
}

nav > * {
  font-family: inherit;
}

.create-reminder-form-container {
  padding: 2rem;
}
@media only screen and (max-width: 40rem) {
  .create-reminder-form-container {
    padding: 0.5rem;
  }
}

.reminder-date-content {
  min-width: 19rem;
}

@media only screen and (max-height: 15rem) {
  body .pos-sticky,
  body .pos-fixed {
    position: static !important;
  }
}
@media only screen and (max-width: 40rem) {
  body .pos-sticky,
  body .pos-fixed {
    position: static !important;
  }
  body .content-max-width.gutters {
    padding: 0;
  }
  body .top-header-nav {
    margin: 0 !important;
  }
  body .header logout-link {
    margin: 0 !important;
  }
  body .header .product-title {
    width: 2.5rem;
    overflow: hidden;
    padding: 0.3125rem;
  }
  body .header .product-title img {
    min-width: 140px !important;
  }
}
#app-support-drawer {
  --hi-drawer--width--left-right--container: 100%;
}

.modal-header {
  /* this is a hack to place the title over svgs on scroll */
  z-index: 1;
}

ai-prefab-search hi-form hi-input-group {
  margin-bottom: 0 !important;
}

.app-store-modal {
  z-index: 100000;
  position: fixed !important;
}

.app-store-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  position: fixed;
  height: 100vh;
}

.app-store-navigate-logo {
  object-fit: cover;
  object-position: 0%;
  width: 3em;
  height: 3em;
}

.sgd-screen {
  --view-template-header--height: 4rem;
  --app-nav-bar--height: 4rem;
  --micro-panel-single-screen--margin: 4rem;
  --deferred-layout-screen--margin: 1rem;
  margin: 0;
  width: 100%;
  height: calc(100vh - var(--hi-screen--nav-primary) - var(--hi-screen--nav-secondary));
  align-items: flex-start;
  flex-flow: row nowrap;
  display: flex;
}

responsive-navigation {
  display: block;
  height: 100vh;
  width: 0;
}

/**
 * we do not want the left-navigation for these configurations
 */
.sgd-screen.screen--micro-full > responsive-navigation,
.sgd-screen.screen--micro-course-schedule > main.sgd-screen__main {
  display: none;
}

/**
 * own the full-width of the screen
 */
.sgd-screen.screen--micro-full > main.sgd-screen__main,
.sgd-screen.screen--deferred-layout > main.sgd-screen__main,
.sgd-screen.screen--micro-course-schedule > main.sgd-screen__main {
  min-height: 100%;
  height: 100%;
}

/**
 * we keep the screen full width for content underneath
 */
.sgd-screen.screen--micro-full > main.sgd-screen__main,
.sgd-screen.screen--micro-course-schedule > main.sgd-screen__main {
  width: 100%;
}

/* this is for student-scheduling, but we have applied it to any wide-screen interface */
.sgd-screen.screen--micro-panel-wide main.sgd-screen__main ng-hil-component > ai-view {
  padding: 1rem;
}

.sgd-screen.screen--micro-panel-wide > main.sgd-screen__main,
.sgd-screen.screen--micro-panel-single > main.sgd-screen__main {
  /* x-area="region" */
  border-radius: var(--hi-area--border-radius);
  background-color: var(--hi-clr--white);
  box-shadow: var(--hi-area--shadow);
  padding: var(--hi-area--padding);
  border: var(--hi-area--border);
  border-color: var(--hi-clr--gray-4);

  /* custom to main screen */
  overflow-x: hidden;
  margin: var(--hi-sz-9);
  max-width: calc(var(--hi-media--small) + var(--hi-sz-11) + var(--hi-sz-11));
  margin-left: calc(var(--hi-screen--nav-tertiary-opened) + var(--hi-sz-9));
  width: calc(100% - calc(var(--hi-screen--nav-tertiary) + var(--hi-sz-9)));
  height: calc(
    100vh - var(--hi-sz-9) - var(--hi-sz-9) - var(--hi-screen--nav-primary) - var(--hi-screen--nav-secondary)
  );
}

.sgd-screen.screen--micro-panel-single > main.sgd-screen__main section[x-layout-screen='main'] {
  height: calc(
    100vh - var(--micro-panel-single-screen--margin) - var(--app-nav-bar--height) - var(--hi-screen--nav-primary) -
      var(--hi-screen--nav-secondary)
  );
}

.sgd-screen.screen--micro-panel-wide > main.sgd-screen__main {
  max-width: 100%;
  overflow-x: auto;
}

.sgd-screen.screen--deferred-layout > main.sgd-screen__main {
  margin: var(--hi-sz-6);
  margin-left: calc(var(--hi-screen--nav-tertiary) + var(--hi-sz-6));
  margin-bottom: 0;
  width: calc(100% - calc(var(--hi-screen--nav-tertiary)));
}

.sgd-screen.screen--deferred-layout > main.sgd-screen__main ai-view [x-area='region'] {
  height: calc(
    100vh - var(--deferred-layout-screen--margin) - var(--view-template-header--height) - var(--app-nav-bar--height) -
      var(--hi-screen--nav-primary) - var(--hi-screen--nav-secondary)
  );
}

.sgd-screen.screen--deferred-layout > main.sgd-screen__main nav-host[x-layout-system='blueprint'][x-layout-barrier],
.sgd-screen.screen--deferred-layout > main.sgd-screen__main ai-view {
  height: calc(
    100vh - var(--deferred-layout-screen--margin) - var(--app-nav-bar--height) - var(--hi-screen--nav-primary) -
      var(--hi-screen--nav-secondary)
  );
}

.sgd-screen.screen--deferred-layout > main.sgd-screen__main ai-view {
  background-color: var(--hi-clr--transparent);
}

@media only screen and (max-width: 40rem) {
  .screen--micro-panel-single.sgd-screen > main.sgd-screen__main,
  .screen--micro-panel-wide.sgd-screen > main.sgd-screen__main {
    margin: 0;
    padding: 0;
    padding-top: var(--hi-sz-11);
    border-radius: 0;
    box-shadow: none;
    max-width: 100%;
    width: 100%;
    border: none;
  }
}

/* Navigate old alert
TECH DEBT: Remove and use ui-sass-alert */

.application-error,
#accountHolds {
  position: absolute;
  left: 50%;
  right: auto;
  width: auto;
  top: 82px;
  z-index: 100000;
  transform: translateX(-50%);
}

.login-content .application-error {
  padding-left: 2rem;
  position: relative;
  top: 0;
  left: 0;
  transform: translateX(0%);
}

phx-notification .alert {
  max-width: 20rem;
  width: auto;
}

html,
body {
  background: var(--hi-clr--gray-2);
  min-height: 100%;
}

body.hide-overflow {
  overflow: hidden;
}

body > section {
  min-height: 100%;
}

.page .view {
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

@media print {
  .page .view {
    min-height: 0;
  }
}

hi-checkbox {
  white-space: normal;
}

.print-only {
  display: none;
}

@media print {
  /* add this class and it will not display for print version */
  .page {
    overflow: visible !important;
  }
  .hide-print {
    display: none !important;
  }
  /* add this class and it will only display for print version */
  .print-only {
    display: inline-block;
  }
}

.wrap {
  white-space: normal;
  overflow: auto;
  -ms-text-overflow: clip;
  -o-text-overflow: clip;
  text-overflow: clip;
}

.nowrap,
th,
td {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.hide {
  position: absolute;
  left: -9999px;
}

.is-disabled,
[disabled],
.is-muted {
  color: var(--hi-clr--gray-8) !important;
  cursor: default !important;
}

.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

.is-invisible {
  visibility: hidden !important;
}

.list--unstyled,
.list--inline,
.list--flat,
.nav,
.sub-tabs,
.form-vert,
.global-menu__sections,
.global-menu__sub-sections {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tooltip-link,
.tooltip-link:hover {
  border-bottom: 2px dotted #0070cd;
}

[ui-view] {
  display: block;
}

ui-view[name='appointment'] {
  position: relative;
}

button.transparent:focus,
button.transparent:active {
  text-decoration: none;
}

body.modal-activate,
body.modal-activated {
  overflow: hidden;
  pointer-events: auto;
}

.modal-active {
  pointer-events: auto;
  z-index: 10000;
}

.button,
button {
  font-family: var(--hi-font--family-roboto);
}
.button hi-icon,
button hi-icon {
  cursor: pointer;
}
.button.transparent.clr-sky-txt,
.button.transparent.text-link,
button.transparent.clr-sky-txt,
button.transparent.text-link {
  color: var(--hi-clr--blue-3);
}

.navigation.navigation-horizontal a {
  cursor: pointer;
}

.app-content-modal {
  padding: 1.5625rem;
}

ul.no-dot,
ol.no-dot {
  list-style: none;
  margin-left: 0;
}
ul.no-dot li,
ol.no-dot li {
  list-style: none;
}

.tile .corner {
  position: absolute;
  right: 0;
  top: 2px;
}

.form-outer hi-button[stretched] {
  color: white !important;
}

popup-container {
  z-index: calc(var(--hi-depth--modal) - 9);
}

.sr-only,
.hide-read,
.screen-reader-text {
  display: block;
  position: absolute;
  z-index: -1000;
  left: -100000px;
  top: -100000px;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
  padding: 0;
  border: 0;
  margin: 0;
}


[handle='app-alerts'] {
  visibility: visible;
  opacity: 1;
}

phx-notification .notification.notification--right .notification__list {
  max-width: 360px;
}

.my.schedule [handle='app-alerts'] {
  visibility: hidden;
  opacity: 0;
  display: none;
}

#appLogo:hover {
  background: var(--hi-clr--blue-1);
}

[data-whatinput='keyboard'] #appLogo:focus {
  outline: 1px solid var(--hi-clr--outline);
}

hi-navigation-primary,
hi-navigation-secondary {
  position: sticky !important;
  top: 0;
}

hi-navigation-primary {
  display: grid;
  z-index: var(--hi-depth--modal);
}

hi-navigation-secondary {
  z-index: var(--hi-depth--modal-overlay);
  top: var(--hi-screen--nav-primary);
}

hi-navigation-secondary .product-title > img {
  height: var(--hi-sz-9);
}

my-header hi-navigation-secondary hi-link {
  padding-right: var(--hi-size--small-xxx);

  &:last-child {
    padding-right: 0;
  }
}

body[screen-device='mobile'] hi-navigation-secondary hi-link {
  margin-left: 0 !important;
}

.header .product-title {
  width: 10.625rem;
  background-size: auto 70%;
  background-position: 1.25rem center;
  height: 2.6875rem;
  background-image: none !important;
  padding: 0.25rem 0.5rem;
}

.header.svg-header .product-title * {
  opacity: initial;
}

.university-name-banner {
  height: 2.375rem;
  line-height: 2.375rem;
}

.header.authentication.svg-header .product-title {
  width: 100%;
  background-position: left top;
  background-size: auto 100%;
  height: 2.7rem;
  margin-top: 0px;
  overflow: hidden;
  margin-bottom: 1rem;
}
.header.authentication.svg-header .product-title h1.eab {
  height: auto;
}

.student-preview-banner {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  z-index: var(--hi-depth--above-all);
}

.micro-app-nav-bar .app-nav-menu {
  display: none;
}

nav-fp-micro {
  display: block;
  width: 100%;
}

[x-view='fp-overview'][x-region='main'] {
  padding-bottom: 2rem !important;
}

nav-host {
  display: block;
  width: 100%;
}

#resources-nav-bar .app-nav-menu {
  display: none;
}

.sgd-screen__main:has(section[x-view="micro_nav_as3"]) app-nav-bar {
  display: none;
}

.sgd-screen__main:has(eab-nav-knowledge-bot) {
  overflow: hidden;
}

eab-nav-knowledge-bot {
  height: calc(
    100vh - var(--hi-sz-9) - var(--hi-sz-9) - var(--hi-screen--nav-primary) - var(--hi-screen--nav-secondary) - 4rem
  );
  max-width: calc(100% - 0.5rem);
}

#survey-home-nav-bar .app-nav-menu {
  display: none;
}

.my-resources-tabs {
  max-width: 11.875rem;
}

.resouces-navigation li hi-link {
  border-bottom-color: transparent;
}
.resouces-navigation li hi-link span {
  text-decoration: none;
}
.resouces-navigation li hi-link.active {
  border-bottom-color: var(--hi-clr--primary);
}

embedded-phone-number input {
  background-color: transparent;
}

.notification-header {
  color: var(--hi-clr--blue-3);
  background-color: var(--hi-clr--white);
}

.notification-settings-container {
  left: 0;
  right: 0;
}

hi-navigation-tertiary #navMenuControlMobile,
hi-navigation-tertiary #tertiaryNavigation {
  z-index: var(--hi-depth--modal-overlay);
}

.tos-modal .view--tos {
  height: 100%;
}

.tos-modal footer {
  box-shadow: 0px -1px 3px -3px rgba(0, 0, 0, 0.5);
}
